define("frontend/pods/components/input-webhook-events/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DsO6hjJr",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"classNames\",\"value\",\"content\",\"optionLabelPath\",\"optionValuePath\",\"placeholder\",\"multiple\"],[\"form-control\",[35,1],[35,0],\"content.title\",\"content.value\",\"(select the events you want this webhook to receive)\",\"multiple\"]]]]],\"hasEval\":false,\"upvars\":[\"webhookEvents\",\"selected\",\"ui-select\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/input-webhook-events/template.hbs"
    }
  });

  _exports.default = _default;
});