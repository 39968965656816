define("frontend/models/external-webhook", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    url: (0, _model.attr)('string'),
    events: (0, _model.attr)('array'),
    signingSecret: (0, _model.attr)('string'),
    organization: (0, _model.belongsTo)('organization'),
    _executeServerAction: function _executeServerAction(action) {
      var adapter = this.store.adapterFor('application');
      return adapter.executeServerAction('external-webhook', this.id, action, {
        method: 'POST'
      });
    },
    rotateSecret: function rotateSecret() {
      return this._executeServerAction('rotate_secret');
    }
  });

  _exports.default = _default;
});