define("frontend/pods/organizations/organization/external-webhooks/index/route", ["exports", "frontend/mixins/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationExternalWebhooksIndexRoute = Ember.Route.extend(_modal.default, {
    actions: {
      deleteModel: function deleteModel(webhook) {
        var _this = this;

        return this.modal.confirm('Are you sure you want to delete this webhook?').then(function (result) {
          if (result) {
            webhook.rollbackAttributes();
            return webhook.destroyRecord().then(function () {
              return _this.transitionTo('organizations.organization.external-webhooks.index');
            });
          }
        });
      },
      rotateSecret: function rotateSecret(webhook) {
        var _this2 = this;

        webhook.rotateSecret().then(function () {
          _this2.store.findRecord('external-webhook', webhook.id, {
            reload: true
          });
        });
      }
    }
  });
  var _default = OrganizationExternalWebhooksIndexRoute;
  _exports.default = _default;
});