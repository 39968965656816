define("frontend/models/organization", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    users: (0, _model.hasMany)('user'),
    maxAgents: (0, _model.attr)('number'),
    agentScript: (0, _model.attr)('string'),
    microsoftDynamicsDomain: (0, _model.attr)('string'),
    microsoftAzureActiveDirectoryDomain: (0, _model.attr)('string'),
    availableIntegrations: (0, _model.attr)('string'),
    dialMode: (0, _model.attr)('string'),
    sendOutboundCallsToCrm: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    sendLeadUpdatesToCrm: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    deDupeOrgLeads: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    multipleNumberDialing: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    csvPhonePriority: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    csvMobilePhonePriority: (0, _model.attr)('number', {
      defaultValue: 2
    }),
    csvWorkPhonePriority: (0, _model.attr)('number', {
      defaultValue: 3
    }),
    csvHomePhonePriority: (0, _model.attr)('number', {
      defaultValue: 4
    }),
    availableCountryCodes: (0, _model.attr)('string', {
      defaultValue: '1'
    }),
    maxPhoneNumbers: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    logoUrl: (0, _model.attr)('string'),
    disabledAt: (0, _model.attr)('date'),
    defaultConversationDisposition: (0, _model.attr)('string'),
    defaultVoicemailDisposition: (0, _model.attr)('string'),
    defaultBusyDisposition: (0, _model.attr)('string'),
    defaultWrongNumberDisposition: (0, _model.attr)('string'),
    defaultNoAnswerDisposition: (0, _model.attr)('string'),
    defaultGatekeeperDisposition: (0, _model.attr)('string'),
    totalAllowedDials: (0, _model.attr)('number'),
    mostRecentServiceProviderId: (0, _model.attr)('string'),
    defaultSharpspringKeyId: (0, _model.attr)('string'),
    dynamicsLeadFieldMonsterConnectStatus: (0, _model.attr)('string'),
    dynamicsLeadFieldMonsterConnectRecentLeadList: (0, _model.attr)('string'),
    dynamicsLeadFieldMonsterConnectReason: (0, _model.attr)('string'),
    dynamicsContactFieldMonsterConnectStatus: (0, _model.attr)('string'),
    dynamicsContactFieldMonsterConnectRecentLeadList: (0, _model.attr)('string'),
    dynamicsContactFieldMonsterConnectReason: (0, _model.attr)('string'),
    dynamicsCallFieldMonsterConnectStatus: (0, _model.attr)('string'),
    dynamicsCallFieldMonsterConnectLeadList: (0, _model.attr)('string'),
    dynamicsCallFieldMonsterConnectReason: (0, _model.attr)('string'),
    dynamicsCallFieldMonsterConnectDisposition: (0, _model.attr)('string'),
    dynamicsFieldMappings: (0, _model.attr)('string'),
    leadStatuses: (0, _model.hasMany)('lead-status'),
    leadLists: (0, _model.hasMany)('lead-list'),
    teams: (0, _model.hasMany)('team'),
    gongAccounts: (0, _model.hasMany)('gong-account'),
    execvisionAccounts: (0, _model.hasMany)('execvision-account'),
    externalWebhooks: (0, _model.hasMany)('external-webhook'),
    serviceProvider: Ember.computed('mostRecentServiceProviderId', function () {
      var id = this.mostRecentServiceProviderId;

      if (id) {
        return this.store.find('service-provider', id);
      }

      return Ember.RSVP.resolve(null);
    })
  });

  _exports.default = _default;
});