define("frontend/pods/components/partial/external-webhook/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dmn3EVJV",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,5],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\"],[10,\"form\"],[12],[2,\"\\n\"],[6,[37,6],null,[[\"title\"],[\"URL\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,4],null,[[\"type\",\"class\",\"value\",\"placeholder\"],[\"text\",\"form-control\",[35,0,[\"url\"]],\"(webhook url)\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],null,[[\"title\"],[\"Webhook Events\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"value\",\"onChange\"],[[35,0,[\"events\"]],[30,[36,2],[[32,0],[30,[36,1],[[35,0,[\"events\"]]],null]],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"mut\",\"action\",\"input-webhook-events\",\"input\",\"error-list\",\"ui-form-field\"]}",
    "meta": {
      "moduleName": "frontend/pods/components/partial/external-webhook/form/template.hbs"
    }
  });

  _exports.default = _default;
});