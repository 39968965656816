define("frontend/models/server-activity", ["exports", "@ember-data/model", "frontend/utils/time-format"], function (_exports, _model, _timeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    calls: (0, _model.attr)(),
    abandonedCalls: (0, _model.attr)(),
    cpu: (0, _model.attr)(),
    users: (0, _model.attr)(),
    statistics: (0, _model.attr)(),
    at: (0, _model.attr)("date"),
    availabilityMetric: (0, _model.attr)("number"),
    // restructure the data to make it more user-based
    userCalls: Ember.computed("users.[]", "calls.[]", function () {
      var users = this.users;
      var calls = this.calls;

      if (!users || !calls) {
        return [];
      }

      var r = users.map(function (user) {
        var result = Ember.copy(user, true);
        var clientCall = calls.findBy("user_id", user.id);
        result.call = clientCall;

        if (clientCall && clientCall.lead && clientCall.client_id) {
          var connection = {
            lead: clientCall.lead
          };

          if (clientCall.client_id && clientCall.client_id !== clientCall.user_id) {
            connection.client = users.findBy("id", clientCall.client_id);
          }

          result.connectedTo = [connection];
        }

        return result;
      });
      var roleOrder = ["client", "client_manager", "agent"];

      var compareByRole = function compareByRole(a, b) {
        var roleA = roleOrder.indexOf(a.role);
        var roleB = roleOrder.indexOf(b.role);
        return roleA - roleB;
      };

      return r.sort(compareByRole);
    }),
    clientCalls: Ember.computed("userCalls.[]", function () {
      var _this = this;

      var userCalls = this.userCalls;
      var agents = userCalls.filterBy("role", "agent");
      var clients = userCalls.rejectBy("role", "agent");
      var clientAgentDials = {};
      clients.forEach(function (c) {
        return clientAgentDials[c.id] = [];
      });
      agents.forEach(function (a) {
        return (a.connectedTo || []).forEach(function (c) {
          var clientId = c.client && c.client.id;

          if (clientId) {
            return clientAgentDials[clientId].push(a);
          }
        });
      });
      var result = clients.map(function (client) {
        var agentCallCount = clientAgentDials[client.id].length;

        var andiCallCount = _this.calls.filter(function (call) {
          return call.is_andi_call && call.client_id === client.id && call.state !== "ended";
        }).length;

        return {
          user: client,
          calls: clientAgentDials[client.id],
          agentCallCount: agentCallCount,
          andiCallCount: andiCallCount,
          avg_wait_time: client.call.dial_count && client.call.idle_time > 0 ? _timeFormat.default.hhmmss(client.call.idle_time / client.call.dial_count) : client.call.idle_time > 0 ? _timeFormat.default.hhmmss(client.call.idle_time) : _timeFormat.default.hhmmss(0)
        };
      });
      var priorityOrder = ["aiOnlyClients", "blendedClients", "agentOnlyClients"];

      var compareByCallType = function compareByCallType(a, b) {
        var getCategory = function getCategory(obj) {
          var isAICompany = obj.user.company_name && obj.user.company_name.trim().toUpperCase().endsWith("AI");

          if (obj.andiCallCount > 0 && obj.agentCallCount <= 0 || isAICompany) {
            // if (isAICompany) {
            return "aiOnlyClients";
          }

          if (obj.andiCallCount > 0 && obj.agentCallCount > 0) {
            return "blendedClients";
          }

          return "agentOnlyClients";
        };

        var categoryA = priorityOrder.indexOf(getCategory(a));
        var categoryB = priorityOrder.indexOf(getCategory(b));
        return categoryA - categoryB || a.user.last_name.localeCompare(b.user.last_name);
      };

      return result.sort(compareByCallType);
    }),
    connectedUsers: Ember.computed("userCalls.[]", function () {
      var userCalls = this.userCalls;
      return userCalls.filter(function (u) {
        return u.call.state === "connected";
      });
    }),
    connectedClients: Ember.computed("connectedUsers.[]", function () {
      var users = this.connectedUsers;
      return users.rejectBy("role", "agent");
    }),
    connectedAgents: Ember.computed("connectedUsers.[]", function () {
      var users = this.connectedUsers;
      return users.filterBy("role", "agent");
    }),
    agents: Ember.computed("users.[]", function () {
      return this.users.filterBy("role", "agent");
    }),
    clients: Ember.computed("users.[]", function () {
      return this.users.rejectBy("role", "agent");
    }),
    idleUsers: Ember.computed("userCalls.[]", function () {
      var userCalls = this.userCalls;
      return userCalls.filter(function (u) {
        return u.call.state === "idle";
      });
    }),
    idleClients: Ember.computed("idleUsers.[]", function () {
      return this.idleUsers.rejectBy("role", "agent");
    }),
    idleAgents: Ember.computed("idleUsers.[]", function () {
      return this.idleUsers.filterBy("role", "agent");
    }),
    pausedUsers: Ember.computed("userCalls.[]", function () {
      var userCalls = this.userCalls;
      return userCalls.filter(function (u) {
        return u.call.state === "paused";
      });
    }),
    pausedClients: Ember.computed("pausedUsers.[]", function () {
      return this.pausedUsers.rejectBy("role", "agent");
    }),
    pausedAgents: Ember.computed("pausedUsers.[]", function () {
      return this.pausedUsers.filterBy("role", "agent");
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.userCalls;
    }
  });

  _exports.default = _default;
});