define("frontend/pods/organizations/organization/external-webhooks/index/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "webhooks-header-container": "_webhooks-header-container_snz3dk"
  };
  _exports.default = _default;
});