define("frontend/pods/organizations/organization/external-webhooks/route", ["exports", "frontend/mixins/modal"], function (_exports, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationExternalWebhooksRoute = Ember.Route.extend(_modal.default, {
    requiresLogin: true,
    authorizedRoles: ['admin', 'client_manager'],
    model: function model() {
      var model = this.modelFor('organizations.organization');
      return model.store.query('external-webhook', {
        organization_id: model.id
      }).then(function () {
        return model;
      });
    }
  });
  var _default = OrganizationExternalWebhooksRoute;
  _exports.default = _default;
});