define("frontend/pods/components/input-webhook-events/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InputWebhookEventsComponent = Ember.Component.extend({
    value: null,
    onChange: function onChange() {},
    selected: Ember.computed('value', {
      get: function get() {
        var value = this.value;
        return value || [];
      },
      set: function set(key, value) {
        this.onChange(value || []);
        return value;
      }
    }),
    // TODO: Move this to a config maybe, or pull them from the server somehow
    webhookEvents: [{
      value: 'lead.created',
      title: 'lead.created'
    }, {
      value: 'lead.updated',
      title: 'lead.updated'
    }, {
      value: 'lead.deleted',
      title: 'lead.deleted'
    }, {
      value: 'lead_list.created',
      title: 'lead_list.created'
    }, {
      value: 'lead_list.updated',
      title: 'lead_list.updated'
    }, {
      value: 'lead_list.deleted',
      title: 'lead_list.deleted'
    }, {
      value: 'call_session.created',
      title: 'call_session.created'
    }, {
      value: 'call_session.updated',
      title: 'call_session.updated'
    }]
  });
  var _default = InputWebhookEventsComponent;
  _exports.default = _default;
});