define("frontend/pods/organizations/organization/external-webhooks/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OrganizationExternalWebhooksController = Ember.Controller.extend({});
  var _default = OrganizationExternalWebhooksController;
  _exports.default = _default;
});