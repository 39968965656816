define("frontend/pods/organizations/organization/external-webhooks/external-webhook/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ExternalWebhookRoute = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('external-webhook', params.external_webhook_id);
    }
  });
  var _default = ExternalWebhookRoute;
  _exports.default = _default;
});